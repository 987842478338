<template>
  <div>
    <div class="mb-2">
      <v-btn
        small
        class="green white--text mr-4 mb-4"
        @click="createRecord"
      >
        新增紀錄
      </v-btn>

      <v-btn
        small
        class="orange white--text mr-4 mb-4"
        @click="quickCreateCost"
      >
        快速新增
      </v-btn>

      <v-btn
        small
        class="deep-orange lighten-2 white--text mb-4"
        @click="$helper.sumAllNumberInStringPopup()"
      >
        快速計算金額加總
      </v-btn>
    </div>

    <div class="mb-2">
      <v-icon
        @click="loadRecommendedCost"
        left
        x-small
      >
        fa fa-sync
      </v-icon>

      <span>常用開銷</span>
    </div>
    
    <div class="mb-2">
      <v-chip
        v-for="(cost, index) in recommendedCosts"
        :key="index"
        small
        label
        class="blue white--text mr-2 mb-2"
        @click="addRecommendedCost(cost)"
      >
        {{ cost.name }}
      </v-chip>
    </div>

    <div class="pt-4">
      <record
        class="mb-4"
        @removeRecord="removeRecord"
        v-for="(record, index) in records"
        :key="index"
        :index="index"
        :deleteIds="[]"
        v-model="records[index]"
      />
    </div>
  </div>
</template>

<script>
import accountingConstants from 'modules/accounting/config/accountingConstants'
export default {
  props: {
    popupConfig: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    loading: false,
    records: [],
    createRecordScrollToBottomTimeout: null,
  }),
  methods: {
    async loadRecommendedCost() {
      this.loading = true
      this.$emit('updateApplyProcessing', true)
      try {
        const result = await this.$api.collection.accountingApi.recommendedCost()
        this.$store.commit('accounting/setRecommendedCosts', result.data)
      } catch(error) {
        console.error(error)
      } finally {
        this.loading = false
        this.$emit('updateApplyProcessing', false)
      }
    },
    addRecommendedCost(cost) {
      this.$apopup.base({
        title: `確定要新增開銷嗎?`,
        content: cost.name,
        applyCallback: () => {
          this.records.push({
            record_type: accountingConstants.TYPE_COST,
            name: cost.name,
            type: cost.type,
            amount: 0,
            date: this.date,
          })
          this.$snotify.success(cost.name, '新增成功')
        },
      })
    },
    quickCreateCost(cost) {
      this.$apopup.prompt({
        title: `快速新增開銷`,
        promptPlaceholder: '範例: 早餐80,飲料50,晚餐100',
        promptMessage: '可善用語音輸入加速新增',
        disabledApply: (data) => {
          if (!data) {
            return true
          }
        },
        applyCallback: (data) => {
          const result = [...data.matchAll(/([a-zA-Z\u4e00-\u9fa5]+)(\d+)/g)].map(match => ({
            name: match[1],
            amount: Number(match[2]),
          }))
          for (const record of result) {
            this.records.push({
              record_type: accountingConstants.TYPE_COST,
              name: record.name,
              type: accountingConstants.COST_TYPE_FOOD,
              amount: record.amount,
              date: this.date,
            })
            this.$snotify.info(record.name, '新增成功')
          }
          if (result.length === 0) {
            this.$snotify.warning('請輸入正確格式', '無新增任何紀錄')
          }
        },
      })
    },
    removeRecord(data) {
      const index = data.index
      this.$delete(this.records, index)
    },
    createRecord() {
      this.records.push({
        record_type: accountingConstants.TYPE_COST,
        name: '',
        amount: 0,
        date: this.date,
        type: accountingConstants.COST_TYPE_FOOD,
      })
      window.clearTimeout(this.createRecordScrollToBottomTimeout)
      this.createRecordScrollToBottomTimeout = null
      this.createRecordScrollToBottomTimeout = window.setTimeout(() => {
        this.$emit('scrollToPopupBottom')
      }, 800)
    },
  },
  computed: {
    date() {
      return this.popupConfig.date
    },
    recommendedCosts() {
      return this.$store.getters['accounting/recommendedCosts']
    },
    recordTypes() {
      return accountingConstants.TYPES
    },
    applyData() {
      return {
        create: this.records,
      }
    },
  },
  components: {
    record: () => import('./recordNew.vue'),
  },
  watch: {
    applyData: {
      deep: true,
      handler: function() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.applyData))
      },
    },
  },
}
</script>